<template>
    <div class="details-wrapper">
        <global-page-back :detailPageTitle="detailPageTitle" @handlerGlobalType="handlerGlobalType"></global-page-back>
        <div class="details-content">
            <table-data v-loading="loadingTable" :to-top="false" id="table" ref="table" :config="tableConfig"
                    :tableData="tableData">
                    <template v-slot:formTitle="slotData">
                        <div style="color: #3c7fff; cursor: pointer" @click="openDetails(slotData.data)">{{
            slotData.data.name }}</div>
                    </template>
                    <template v-slot:operation="slotData">
                        <el-button type="text"
                            @click="showAnswer(slotData.data)">查看答案</el-button>
                    </template>
                </table-data>
                <Pagination :page.sync="pageData.pageNum" :limit.sync="pageData.pageRow" :total="pageData.total"
                    @pagination="changeTableDataGet" />
                <dialog-wrapper class="details-dia" :dialog-obj="detailsDialog" @handleClose="handleDetailsClose">
                    <div class="import-wrap">
                        <!-- <div>请设置新的表单填写的截止时间：</div> -->
                        <div style="text-align: center;">
                            <phone-details :detailsObj="detailsObj" :formTitle="formTitle"></phone-details>
                        </div>
                        <div class="close-box">
                            <i class="el-icon-circle-close icon-color" @click="handleDetailsClose"></i>
                        </div>
                    </div>
                </dialog-wrapper>
        </div>
    </div>
  </template>

  <script>
  import TabbedPage from "@/components/scrollWrapper/Sub/TabbedPage/index.vue";
  import { Loading, Error, Pagination,DialogWrapper,debounce } from "common-local"
  import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
  import TableData from "@/components/scrollWrapper/Sub/TableData"
  import RecordsList from '@/libs/pageListOptimal.js';
  import PhoneDetails from '@/components/scrollWrapper/InterconnectionInfoCollection/phoneDetailsResults.vue'
  import { InterconnectionInfoCollectionStatostics } from "@/models/InterconnectionInfoCollectionStatostics.js";
  export default {
    name:'UserFillingStatus',
    props:["id","teacherId","formType","teacherName",],
    components:{
        GlobalPageBack,
        TableData,
        PhoneDetails,
        Loading,
        Error,
        Pagination,
        DialogWrapper,
        TabbedPage
    },
    data () {
          return {
            detailPageTitle:'详情',
            loadingTable: false,
            tableData:[],
            detailsObj: [],
            formTitle:"",
            pageData: {
                pageNum: 1,
                pageRow: 20,
                total: 0
            },
            detailsDialog: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            tableConfig: {
                thead: [
                    {
                        label: "学生姓名",
                        // labelWidth: '180px',
                        prop: "filledName",
                        align: "center",
                    },
                    {
                        label: "所在班级",
                        // labelWidth: '180px',
                        prop: "organName",
                        align: "center",
                    },
                    {
                        label: "打分明细(分)",
                        // labelWidth: '180px',
                        prop: "answerInfo",
                        align: "center",
                    },
                    {
                        label: "最终结果(分)",
                        // labelWidth: '180px',
                        labelDescription: "根据选项最高分转换为百分制后的多维度平均分",
                        prop: "score",
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: '180px',
                        slotName: "operation",
                        className: 'text-spacing-reduction',
                        renderHeader: null,
                    }
                ],
                height: "",
                check: false,
                showIndex: true,
                showIndexWidth: "150px"
            },
          }
    },
    mounted() {
        console.log('teachername',this.teacherName)
        this.detailPageTitle = this.teacherName + '的问卷详情'
        this.tableConfig.height = document.body.clientHeight - document.getElementById('table').offsetTop - 146
        window.onresize = debounce(() => {
            if (!document.getElementById('table')) return
            const height = document.body.clientHeight - document.getElementById('table').offsetTop - 146
            this.tableConfig.height = height.toString()
        }, 200)
        this.getList()
    },
    methods : {
        handlerGlobalType() {
            this.$emit('changeComp',{
                comp:'EvaluationOfTeacherStatistics'
            })
        },
        /**
         * @Description: 查看答卷详情列表
         * @Author: 王梦瑶
         * @Date: 2024-08-16 14:07:34
         */
        getList(){
            const InterconnectionInfoCollectionStatosticsModel = new InterconnectionInfoCollectionStatostics()
            InterconnectionInfoCollectionStatosticsModel.getSchoolCollectDetailsList({
                schoolId: this.$store.state.schoolId,
                formId:this.id,
                teacherId:this.teacherId,
                type: '0',
                pageNum: this.pageData.pageNum,
                pageRow: this.pageData.pageRow
            }).then(res => {
                if (res.data.code === "200") {
                    this.tableData = res.data.data.list
                    this.pageData.total = res.data.data.total
                    //赋值--存储筛选项及滚动条高度
                    this.$nextTick(() => {
                        let recordsList = new RecordsList()
                        if (recordsList.get(this.$route.name)) {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        }
                        //移除--存储筛选项及滚动条高度
                        recordsList.remove(this.$route.name)
                        this.loadingTable = false;
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            }).finally(() => {
                this.loadingTable = false
            })
        },
        /**
         * @Description: 修改页码重新请求
         * @Author: 王梦瑶
         * @Date: 2024-08-16 15:15:01
         */
        changeTableDataGet(){
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.getList()
        },
        /**
         * @Description: 打开查看答案弹窗
         * @Author: 王梦瑶
         * @Date: 2024-08-08 15:58:51
         */
        showAnswer(row){
            this.formTitle = row.filledName
            // 获取答案
            // getAnwer
            const InterconnectionInfoCollectionStatosticsModel = new InterconnectionInfoCollectionStatostics()
            InterconnectionInfoCollectionStatosticsModel.getAnwer(row.recordId).then((res)=>{
                if(res.data.code == 200){
                    let content = JSON.parse(res.data.data.answerInfo).content
                    let info = JSON.parse(JSON.parse(content))
                    this.detailsObj = info
                    this.detailsObj.forEach((item)=>{
                        if(item.compType == 'multipleSelect' && item.value){
                            item.defaultValue = item.value.map((i)=>{
                                return i.label
                            })
                        }
                    })
                    this.detailsDialog.dialogVisible = true
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    });
                }
            }).finally(()=>{

            })

        },
        /**
         * @Description: 关闭表单详情
         * @Author: 王梦瑶
         * @Date: 2024-08-08 14:57:04
         */
         handleDetailsClose() {
            this.detailsDialog.dialogVisible = false
        },
    },
  }
  </script>

<style lang="scss" scoped>
.details-wrapper{
    // background-color: #EDF0F7;
    .details-content{
        height: calc(100vh - 180px);
        background-color: #fff;
        margin-top: 10px;
    }
}
.invalid-btn{
    color: #E63A2E !important;
}

.close-box {
    text-align: center;
    font-size: 40px;
}

.icon-color {
    color: #fff;
    cursor: pointer;
}
</style>
<style lang="scss">
.details-dia {
    .el-dialog {
        background: transparent;
        box-shadow: none;
    }

    .el-dialog .el-dialog__body {
        margin-top: 0px;
        padding: 0px;
    }

    .el-dialog__header {
        display: none !important;
    }
}
</style>
